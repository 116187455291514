

import {Component, PropSync, Vue} from "vue-property-decorator";
import {NetworkProfile, NetworkProfileIpRange} from "@/domain";

@Component
export default class NetworkProfileForm extends Vue {

  @PropSync('name', {type: String, required: true})
  readonly nameModel!: string;

  @PropSync('ipRanges', {type: Array, required: true})
  readonly ipRangesModel!: NetworkProfileIpRange[];

  @PropSync('errors', {type: Object, required: true})
  readonly errorsModel!: { [p: string]: string };

  addCidr() {
    this.ipRangesModel.push({ipCidrRange: ''});
  }

  removeCidr(index: number) {
    this.ipRangesModel.splice(index, 1);
  }

  mounted() {
    if (this.ipRangesModel.length === 0) {
      this.addCidr();
    }
  }

}

export function validateForm(networkProfile: Partial<NetworkProfile>, setError: (formKey: string, message: string) => void, deleteError: (formKey: string) => void): boolean {
  let valid = true;
  if (!networkProfile.name) {
    valid = false;
    setError('name', 'Name is required');
  } else {
    deleteError('name');
  }
  const ipRanges = networkProfile.ipRanges;
  if (!ipRanges || ipRanges.filter((ip) => !!ip.ipCidrRange).length === 0) {
    valid = false;
    setError('ipRanges', 'Adding at least 1 IP Range is required')
  } else {
    deleteError('ipRanges');

    const nonEmptyIpRanges = ipRanges.filter((ip) => !!ip.ipCidrRange);
    for (let i = 0; i < nonEmptyIpRanges.length; i++) {
      const ipRange = nonEmptyIpRanges[i];
      const cidr = ipRange.ipCidrRange;
      if (cidr.indexOf(':') === -1) {
        if (!validateIpv4Cidr(cidr)) {
          valid = false;
          setError(`ipRanges[${i}].ipCidrRange`, 'Invalid value, use CIDR notation like: 1.2.3.4/32');
        } else {
          deleteError(`ipRanges[${i}].ipCidrRange`);
        }
      } else {
        // Not guaranteed to be 100% strict, but backend will handle that.
        if (!validateIpv6Cidr(cidr)) {
          valid = false;
          setError(`ipRanges[${i}].ipCidrRange`, 'Invalid value, use CIDR notation like: 2001:db8:85a3::8a2e:370:7134/128');
        } else {
          deleteError(`ipRanges[${i}].ipCidrRange`);
        }
      }
    }
  }

  return valid;
}

export function validateIpv4Cidr(cidr: string) {
  if (!/^(\d{1,3}\.){3}\d{1,3}\/\d{1,2}$/.exec(cidr)) {
    return false;
  }
  const [ipPart, subnetPart] = cidr.split('/');
  const ipSubParts = ipPart.split('.');
  if (ipSubParts.length !== 4 ||
      ipSubParts.find((part) => {
        return part.length > 3 ||
            isNaN(parseInt(part)) ||
            parseInt(part) < 0 ||
            parseInt(part) > 255
      })) {
    return false;
  }
  if (subnetPart && (parseInt(subnetPart) > 32 || parseInt(subnetPart) < 0)) {
    return false;
  }
  return true;
}

export function validateIpv6Cidr(cidr: string) {
  if (cidr.indexOf('/') === -1) {
    return false;
  }
  const [ipPart, subnetPart] = cidr.split('/');
  const ipSubParts = ipPart.split(':');
  if (ipSubParts.length > 8 ||
      ipSubParts.length < 3 ||
      (ipSubParts.length !== 8 && ipPart.indexOf('::') === -1) ||
      ipSubParts.find((part) => {
        return part.length > 4 ||
            (part !== '' && isNaN(parseInt(part, 16))) ||
            parseInt(part, 16) < 0 ||
            parseInt(part, 16) > 0xffff
      })) {
    return false;
  }
  if (subnetPart && (parseInt(subnetPart) > 128 || parseInt(subnetPart) < 0)) {
    return false;
  }
  return true;
}
