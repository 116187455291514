
import {Component, Vue} from "vue-property-decorator";

import RouteDialog from "../components/RouteDialog.vue";
import {NetworkProfile, NetworkProfileIpRange} from "@/domain/network-profile";
import NetworkProfileForm, {validateForm} from "@/components/NetworkProfileForm.vue";
import {RouteDialogConfirmEvent} from "@/domain";

@Component({
  components: {NetworkProfileForm, RouteDialog},
})
export default class NetworkProfileNew extends Vue {
  networkProfile: Partial<NetworkProfile> = {
    name: '',
    ipRanges: [],
  };

  errorsData: { [p: string]: string } = {};

  get errors() {
    return this.errorsData;
  }

  async onConfirm(evt: RouteDialogConfirmEvent) {
    const valid = validateForm(
        this.networkProfile,
        (formKey: string, message: string) => {
          this.$set(this.errorsData, formKey, message);
        },
        (formKey: string) => {
          this.$delete(this.errorsData, formKey);
        }
    );
    if (!valid) {
      evt.cancel();
      return;
    }
    let ipRanges = this.networkProfile.ipRanges!;
    ipRanges = ipRanges.filter((ipRange: NetworkProfileIpRange) => ipRange.ipCidrRange !== '');
    await this.$store.dispatch("networkProfiles/createOne", {
      name: this.networkProfile.name,
      ipRanges: ipRanges,
    })
        .then(() => evt.done())
        .catch((error) => {
          console.error('Failed creating network profile', error);
          window.alert('An error occurred while creating the network profile\n\n' + (error.message || JSON.stringify(error)));
          evt.cancel();
        });
  }
}
